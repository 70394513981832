@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

.active {
  border-left: 4px solid #ff6700 !important;
  background-color: #ff660010 !important;
  color: #ff6700;
}

/* Target the scrollbar */
::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

/* Target the thumb (the part of the scrollbar that you can drag) */
::-webkit-scrollbar-thumb {
  background-color: #65656566;
  border-radius: 10px;
}
/*...............*/
/* customStyles.css */

@tailwind base;
@tailwind components;
@tailwind utilities;
